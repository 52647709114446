import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import OrdersApp from "./Orders";
import ProductAddApp from "./Product";

const root = ReactDOM.createRoot(document.getElementById("root"));

const path = window.location.href.split("/");
if (path.length > 3 && path[3] === "orders") {
  root.render(
    <React.StrictMode>
      <OrdersApp />
    </React.StrictMode>
  );
} else if (path.length > 3 && path[3] === "product") {
  root.render(
    <React.StrictMode>
      <ProductAddApp />
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
