import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";

import React, { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { download_file } from "./Files.js";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BreadcrumbItem, Breadcrumb } from "react-bootstrap";

const client_field_names = [
  "name",
  "email",
  "address1",
  "address2",
  "city",
  "state",
  "country",
  "zip",
  "source",
  "type",
  "buyer",
  "phone",
  "cell phone",
  "cc",
  "cc name",
  "cc exp",
  "cc cvv",
  "cc billing",
  "notes",
];
var all_orders = [];
const images = {};

class Item extends React.Component {
  render() {
    return (
      <button onClick={this.props.onClick} className="square">
        {this.props.item_info["type"] === "item" ? (
          <div className="thumbnail-and-text-wrapper">
            {this.props.item_info["image"] === "" ? (
              <div className="thumbnail" />
            ) : (
              <img
                className="thumbnail"
                src={this.props.item_info["image"]}
                alt=""
              />
            )}
            <div className="thumbnail-text">{this.props.name}</div>
            <p className="thumbnail-price">
              $
              {this.props.item_info["price"].toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
        ) : (
          <div className="thumbnail-text-category">{this.props.name}</div>
        )}
      </button>
    );
  }
}

export class ShipDate extends React.Component {
  render() {
    if (this.props.is_input) {
      return (
        <div className="ship_date">
          <label htmlFor="ship_date">Ship date:</label>

          <input
            type="date"
            id="ship_date"
            name="ship_date"
            value={this.props.ship_date}
            onChange={(e) => {
              this.props.ship_date_change(e.target.value);
            }}
          />
        </div>
      );
    } else {
      return <p>Ship date: {this.props.ship_date}</p>;
    }
  }
}

function BreadCrumb(props) {
  if (props.hidden) {
    return;
  }
  const breadcrumbs = ["Home"];
  breadcrumbs.push(...props.active_category_keys);

  const bc_display = breadcrumbs.map((bc, ix) => {
    return (
      <BreadcrumbItem
        key={bc}
        onClick={(e) => {
          props.breadcrumb_click([...props.active_category_keys.slice(0, ix)]);
        }}
      >
        {bc}
      </BreadcrumbItem>
    );
  });

  return <Breadcrumb>{bc_display}</Breadcrumb>;
}

class SourceSelect extends React.Component {
  render() {
    return (
      <CreatableSelect
        create-option={true}
        name="source"
        className="select"
        options={["n/a"]
          .concat(
            Object.keys(this.props.accounts).map((key) => {
              return this.props.accounts[key]["source"];
            })
          )
          .concat([this.props.client_info_data["source"]])
          .reduce(
            (unique, item) =>
              unique.includes(item) || item === "" ? unique : [...unique, item],
            []
          )
          .sort()
          .map((value) => {
            return { label: value, value: value };
          })}
        isSearchable={true}
        onChange={(e) => {
          this.props.client_info_data_changed("source", e.value);
        }}
        onCreateOption={(e) => {
          this.props.client_info_data_changed("source", e);
        }}
        value={{
          label:
            this.props.client_info_data.source === ""
              ? "n/a"
              : this.props.client_info_data.source,
          value:
            this.props.client_info_data.source === ""
              ? "n/a"
              : this.props.client_info_data.source,
        }}
      />
    );
  }
}

class ClientSelect extends React.Component {
  render() {
    const options = [{ value: "", label: "--New--" }];
    Object.keys(this.props.accounts).forEach((key) => {
      options.push({ value: key, label: key });
    });
    return (
      <Select
        name="client-select"
        className="select"
        options={options.sort()}
        isSearchable={true}
        onChange={(e) => {
          this.props.client_info_data_changed("selected", e.value);
        }}
        value={{
          value: this.props.selected_value,
          label:
            this.props.selected_value === ""
              ? "--New--"
              : this.props.selected_value,
        }}
      ></Select>
    );
  }
}

class TypeSelect extends React.Component {
  render() {
    return (
      <CreatableSelect
        create-option={true}
        name="type"
        className="select"
        value={{
          label:
            this.props.client_info_data.type === ""
              ? "n/a"
              : this.props.client_info_data.type,
          value:
            this.props.client_info_data.type === ""
              ? "n/a"
              : this.props.client_info_data.type,
        }}
        onChange={(e) => {
          this.props.client_info_data_changed("type", e.value);
        }}
        onCreateOption={(e) => {
          this.props.client_info_data_changed("type", e);
        }}
        options={[
          "n/a",
          "Accommodation",
          "Apparel",
          "Big Box",
          "Corporate Gift",
          "Garden, Hardware, Grocery",
          "Gift & Home",
          "Museum",
          "Resort/Hotel",
          "Salon",
        ]
          .concat(
            Object.keys(this.props.accounts).map((key) => {
              return this.props.accounts[key]["type"];
            })
          )
          .concat([this.props.client_info_data["type"]])
          .reduce(
            (unique, item) =>
              unique.includes(item) || item === "" ? unique : [...unique, item],
            []
          )
          .sort()
          .map((value) => {
            return { label: value, value: value };
          })}
      />
    );
  }
}
export class ClientInfo extends React.Component {
  render() {
    if (this.props.is_input) {
      return (
        <div>
          <form className="client-info-input">
            <label htmlFor="client-select"> Saved clients: </label>
            <ClientSelect {...this.props} />
            <br />
            <label htmlFor="source">Source:</label>
            <SourceSelect {...this.props} />
            <br />
            <label htmlFor="type">Type:</label>
            <TypeSelect {...this.props} />
            <br />
            <label htmlFor="name">Client Name:</label>
            <input
              name="name"
              type="text"
              value={this.props.client_info_data.name}
              onChange={(e) => {
                this.props.client_info_data_changed("name", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="email">Email:</label>
            <input
              name="email"
              type="text"
              value={this.props.client_info_data.email}
              onChange={(e) => {
                this.props.client_info_data_changed("email", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="address1">Address 1:</label>
            <input
              name="address1"
              type="text"
              value={this.props.client_info_data.address1}
              onChange={(e) => {
                this.props.client_info_data_changed("address1", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="address2">Address 2:</label>
            <input
              name="address2"
              type="text"
              value={this.props.client_info_data.address2}
              onChange={(e) => {
                this.props.client_info_data_changed("address2", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="city">City:</label>
            <input
              name="city"
              type="text"
              value={this.props.client_info_data.city}
              onChange={(e) => {
                this.props.client_info_data_changed("city", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="state">State:</label>
            <input
              name="state"
              type="text"
              value={this.props.client_info_data.state}
              onChange={(e) => {
                this.props.client_info_data_changed("state", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="country">Country:</label>
            <input
              name="country"
              type="text"
              value={this.props.client_info_data.country}
              onChange={(e) => {
                this.props.client_info_data_changed("country", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="zip">Zip:</label>
            <input
              name="zip"
              type="text"
              value={this.props.client_info_data.zip}
              onChange={(e) => {
                this.props.client_info_data_changed("zip", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="phone">Buyer:</label>
            <input
              name="buyer"
              type="text"
              value={this.props.client_info_data.buyer}
              onChange={(e) => {
                this.props.client_info_data_changed("buyer", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="phone">Phone:</label>
            <input
              name="phone"
              type="text"
              value={this.props.client_info_data.phone}
              onChange={(e) => {
                this.props.client_info_data_changed("phone", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="cell phone">Cell Phone:</label>
            <input
              name="cell phone"
              type="text"
              value={this.props.client_info_data["cell phone"]}
              onChange={(e) => {
                this.props.client_info_data_changed(
                  "cell phone",
                  e.target.value
                );
              }}
            ></input>
            <br />
            <label htmlFor="cc">Credit card number:</label>
            <input
              name="cc"
              type="text"
              value={this.props.client_info_data.cc}
              onChange={(e) => {
                this.props.client_info_data_changed(
                  "cc",
                  cc_format(e.target.value)
                );
              }}
            ></input>
            <br />
            <label htmlFor="cc name">Credit card name:</label>
            <input
              name="cc name"
              type="text"
              value={this.props.client_info_data["cc name"]}
              onChange={(e) => {
                this.props.client_info_data_changed("cc name", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="cc exp">Credit card exp:</label>
            <input
              name="cc exp"
              type="text"
              value={this.props.client_info_data["cc exp"]}
              onChange={(e) => {
                this.props.client_info_data_changed("cc exp", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="cc cvv">Credit card cvv:</label>
            <input
              name="cc cvv"
              type="text"
              value={this.props.client_info_data["cc cvv"]}
              onChange={(e) => {
                this.props.client_info_data_changed("cc cvv", e.target.value);
              }}
            ></input>
            <br />
            <label htmlFor="cc billing">Credit card billing:</label>
            <input
              name="cc billing"
              type="text"
              value={this.props.client_info_data["cc billing"]}
              onChange={(e) => {
                this.props.client_info_data_changed(
                  "cc billing",
                  e.target.value
                );
              }}
            ></input>
            <br />
            <label htmlFor="notes">Notes:</label>
            <textarea
              name="notes"
              type="text"
              value={this.props.client_info_data.notes}
              onChange={(e) => {
                this.props.client_info_data_changed("notes", e.target.value);
              }}
            ></textarea>
          </form>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            {[
              "name",
              "buyer",
              "address1",
              "address2",
              "city",
              "state",
              "zip",
              "email",
            ].map((key) => {
              if (this.props.client_info_data[key] === "") {
                return "";
              }

              if (key === "city") {
                return (
                  <span key={key}>
                    {this.props.client_info_data[key]} {", "}
                  </span>
                );
              } else if (
                ["buyer", "phone", "cell phone", "notes"].includes(key)
              ) {
                return (
                  <span key={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1) + ": "}
                    {this.props.client_info_data[key]} <br></br>
                  </span>
                );
              }
              return (
                <span key={key}>
                  {this.props.client_info_data[key]} <br></br>
                </span>
              );
            })}
          </p>
        </div>
      );
    }
  }
}

class CartItem extends React.Component {
  render() {
    return (
      <tr>
        <td>
          <div className="thumbnail-and-text-wrapper">
            <div className="thumbnail-wrapper">
              {this.props.cart_item.image === "" ? (
                ""
              ) : (
                <img
                  className="thumbnail"
                  src={this.props.cart_item.image}
                  alt=""
                />
              )}
            </div>
            <div className="thumbnail-text">{this.props.cart_item.id}</div>
          </div>
        </td>
        <td>
          <input
            type="number"
            min="0"
            max="1000000000"
            defaultValue={this.props.cart_item.qty}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              if (e.target.value === "") {
                e.target.value = 0;
              }
              this.props.change_qty(this.props.cart_item.id, e.target.value);
            }}
          ></input>
        </td>
        <td>
          <nobr>
            $
            <input
              type="number"
              min="0"
              max="1000000000"
              defaultValue={this.props.cart_item.price}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value === "") {
                  e.target.value = 0;
                }
                this.props.change_price(
                  this.props.cart_item.id,
                  e.target.value
                );
              }}
            ></input>
          </nobr>
        </td>
        <td>
          <div className="box">
            <textarea
              type="text"
              rows="3"
              defaultValue={this.props.cart_item.notes}
              onChange={(e) => {
                this.props.change_notes(
                  this.props.cart_item.id,
                  e.target.value
                );
              }}
            ></textarea>
          </div>
        </td>
        <td>
          <nobr>
            $
            {(
              this.props.cart_item.price * this.props.cart_item.qty
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </nobr>
        </td>
      </tr>
    );
  }
}

export function Login(props) {
  const [state, setState] = useState({ pw: "" });

  return (
    <Modal show={!props.logged_in}>
      <Modal.Header>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.alert ? (
          <Alert variant={props.alert.variant}>{props.alert.message}</Alert>
        ) : (
          ""
        )}
        <label htmlFor="pwd">Password:</label>
        <input
          name="pwd"
          type="password"
          onChange={(e) => {
            state.pw = e.target.value;
            setState(state);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") props.login(state.pw);
          }}
        ></input>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => props.login(state.pw)}>
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function cc_format(value) {
  const v = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join("-") : value;
}

function CustomInput(props) {
  const [state, setState] = useState({ name: "", price: 0, qty: 0, notes: "" });

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Custom Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor="custom_name">Name:</label>
        <input
          name="custom_name"
          type="text"
          onChange={(e) => {
            state.name = e.target.value;
            setState(state);
          }}
        ></input>
        <label htmlFor="qty">Qty:</label>
        <input
          name="qty"
          type="number"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            if (e.target.value === "") {
              e.target.value = 0;
            }
            state.qty = parseFloat(e.target.value);
            setState(state);
          }}
        ></input>
        <label htmlFor="price">Price:</label>
        <input
          name="price"
          type="number"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            if (e.target.value === "") {
              e.target.value = 0;
            }
            state.price = parseFloat(e.target.value);
            setState(state);
          }}
        ></input>
        <label htmlFor="notes">Notes:</label>
        <textarea
          name="notes"
          type="text"
          onChange={(e) => {
            state.notes = e.target.value;
            setState(state);
          }}
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
        <Button variant="primary" onClick={() => props.add_custom(state)}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export class CartView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show_custom_input: false };
  }

  render_pages(sorted_item_keys, header, total, cart_items) {
    const chunkSize = 8;
    let pages = [];
    for (let i = 0; i < sorted_item_keys.length; i += chunkSize) {
      const chunk = sorted_item_keys.slice(i, i + chunkSize);
      const last_chunk = i + chunkSize > sorted_item_keys.length;
      pages.push(
        <>
          <div className="table" id="table">
            <CustomInput
              show={this.state.show_custom_input}
              onHide={() => {
                this.setState({ show_custom_input: false });
              }}
              add_custom={(data) => {
                this.setState({ show_custom_input: false });
                this.props.add_custom(data);
              }}
            />
            <table>
              {header}
              <tbody className="cart-view">
                {chunk.map((cart_item, i) => {
                  return (
                    <CartItem
                      change_qty={this.props.change_qty}
                      change_price={this.props.change_price}
                      change_notes={this.props.change_notes}
                      key={cart_item}
                      cart_item={cart_items[cart_item]}
                    />
                  );
                })}
                {last_chunk ? total : ""}
              </tbody>
            </table>
          </div>
          {last_chunk ? (
            ""
          ) : (
            <>
              <div className="page-break"></div>
              <div className="page-break-before"></div>
            </>
          )}
        </>
      );
    }
    return (
      <>
        {pages.map((page) => {
          return page;
        })}
      </>
    );
  }

  render() {
    const cart_items = this.props.cart_items;

    // Shipping and deposit should be at the end if they exist
    const sorted_item_keys = Object.keys(cart_items)
      .filter(
        (cart_item) => cart_item !== "Shipping" && cart_item !== "Deposit"
      )
      .concat(
        Object.keys(cart_items).filter(
          (cart_item) => cart_item === "Shipping" || cart_item === "Deposit"
        )
      );

    const header = (
      <thead>
        <tr>
          <th id="name">Name</th>
          <th id="qty">Qty</th>
          <th id="price">Price</th>
          <th id="notes">Notes</th>
          <th id="total">Total</th>
        </tr>
      </thead>
    );

    const total = (
      <tr>
        <td>Total</td>
        <td className="center-cell">
          <button
            className="button"
            onClick={() => {
              this.setState({ show_custom_input: true });
            }}
          >
            Add custom
          </button>
        </td>
        <td></td>
        <td></td>
        <td>
          $
          {Object.keys(cart_items)
            .map((key) => {
              return (
                parseFloat(cart_items[key].price) *
                parseInt(cart_items[key].qty)
              );
            })
            .reduce((total, curr) => {
              return total + curr;
            }, 0)
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </td>
      </tr>
    );

    return (
      <div className="wrapper" id="table">
        <CustomInput
          show={this.state.show_custom_input}
          onHide={() => {
            this.setState({ show_custom_input: false });
          }}
          add_custom={(data) => {
            this.setState({ show_custom_input: false });
            this.props.add_custom(data);
          }}
        />
        <table>
          {header}
          <tbody className="cart-view">
            {sorted_item_keys.map((cart_item, i) => {
              return (
                <CartItem
                  change_qty={this.props.change_qty}
                  change_price={this.props.change_price}
                  change_notes={this.props.change_notes}
                  key={cart_item}
                  cart_item={cart_items[cart_item]}
                />
              );
            })}
            {total}
          </tbody>
        </table>
      </div>
    );
  }
}

class MenuRow extends React.Component {
  render() {
    return (
      <div className="menu-row">
        <button
          className="settings-button"
          onClick={() => this.props.cart_button_clicked()}
        >
          🛒 (
          {Object.keys(this.props.cart_items).reduce(
            (accum, key) => accum + this.props.cart_items[key]["qty"],
            0
          )}
          )
        </button>
        <button
          className="settings-button"
          onClick={() => this.props.home_button_clicked()}
        >
          home
        </button>
        <button className="settings-button" onClick={() => this.props.reset()}>
          reset
        </button>
      </div>
    );
  }
}

class Board extends React.Component {
  constructor(props) {
    super(props);

    let ship_date = new Date();
    ship_date.setDate(ship_date.getDate() + 42);
    let date = new Date();

    this.state = {
      active_category_keys: [],
      ship_date: this.props.editing
        ? this.props.edit_order["ship_date"]
        : ship_date.toISOString().slice(0, 10),
      date: this.props.editing
        ? this.props.edit_order["date"]
        : date.toISOString().slice(0, 10),
      cart_items: this.props.editing ? this.props.edit_order["cart_items"] : {},
      cart_view: this.props.editing ? true : false,
      order_number: this.props.editing
        ? this.props.edit_order["order_number"]
        : 0,

      // makes object with field names as keys and empty strings as initial values
      // like { "name": "", email: "", ... }
      client_info_data: this.props.editing
        ? this.props.edit_order["client_info_data"]
        : client_field_names.reduce((a, field_name) => {
            return { ...a, [field_name]: "" };
          }, 0),
      selected_value: "",
    };
  }

  reset() {
    let ship_date = new Date();
    ship_date.setDate(ship_date.getDate() + 30);
    let date = new Date();

    this.setState({
      cart_items: {},
      active_category_keys: [],
      ship_date: ship_date.toISOString().slice(0, 10),
      date: date.toISOString().slice(0, 10),
      cart_view: false,
    });
  }

  cart_button_clicked() {
    this.setState({ cart_view: !this.state.cart_view });
  }

  home_button_clicked() {
    this.setState({ active_category_keys: [], cart_view: false });
  }

  change_notes(item, value) {
    const cart_items = this.state.cart_items;
    if (!(item in cart_items)) {
      console.log("trying to update item that is not in cart", item);
      return;
    }
    cart_items[item]["notes"] = value;
    this.setState({ cart_items: cart_items });
  }

  change_price(item, value) {
    const cart_items = this.state.cart_items;
    value = parseFloat(value);
    if (!(item in cart_items)) {
      console.log("trying to update item that is not in cart", item);
      return;
    }
    if (isNaN(value)) {
      console.log("trying to update with non-number");
      console.log(typeof value);
    }
    if (item === "Deposit" && value > 0) {
      cart_items[item]["price"] = -value;
    } else {
      cart_items[item]["price"] = value;
    }
    this.setState({ cart_items: cart_items });
  }

  change_qty(item, value) {
    const cart_items = this.state.cart_items;
    value = parseInt(value);
    if (!(item in cart_items)) {
      console.log("trying to update item that is not in cart", item);
      return;
    }
    if (isNaN(value)) {
      console.log("trying to update with non-number");
      console.log(typeof value);
      return;
    }
    cart_items[item]["qty"] = value;
    if (cart_items[item]["qty"] === 0) {
      delete cart_items[item];
    }

    this.setState({ cart_items: cart_items });
  }

  change_ship_date(value) {
    this.setState({ ship_date: value });
  }

  client_info_data_changed(field, value) {
    const client_info_data = this.state.client_info_data;
    if (field === "selected") {
      this.setState({ selected_value: value });
      if (value === "") {
        Object.keys(client_info_data).forEach((key) => {
          client_info_data[key] = "";
        });
      } else {
        Object.keys(client_info_data).forEach((key) => {
          // value will be account holders name if selected field
          client_info_data[key] = this.props.accounts[value][key];
        });
      }
    }
    if (field === "saved") {
      let name = client_info_data["name"];
      this.props.accounts[name] = {};
      Object.keys(client_info_data).forEach((key) => {
        this.props.accounts[name][key] = client_info_data[key];
      });
      this.setState({ selected_value: name });
      this.props
        .try_request(
          "/upload/contact",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(client_info_data),
          },
          () => {
            this.props.alert(
              "danger",
              "Failed to save client info to backend",
              2
            );
          }
        )
        .then((data) => {
          if (data && data.status === 200) {
            console.log(data);
            this.props.alert("success", "Saved client info", 2);
          }
        });
    } else {
      client_info_data[field] = value;
    }
    this.setState({ client_info_data: client_info_data });
  }

  handleClick(item) {
    // if its a category not an item
    if (item["type"] === "category") {
      const active_category_keys = this.state.active_category_keys;
      active_category_keys.push(item["name"]);
      this.setState({ active_category_keys: active_category_keys });
      return;
    }
    const cart_items = this.state.cart_items;
    if (!(item["name"] in cart_items)) {
      cart_items[item["name"]] = {
        id: item["name"],
        qty: 0,
        price: item["price"],
        sizes: item["sizes"],
        image: item["image"],
        notes: "",
        categories: [...this.state.active_category_keys], // make a copy
      };
      console.log("adding", Object.keys(cart_items).length);
      // first item added
      if (Object.keys(cart_items).length === 1) {
        cart_items["Shipping"] = {
          // ~ for sorting below other ascii names
          id: "Shipping",
          qty: 1,
          price: 10,
          sizes: [],
          image: "",
          notes: "",
          categories: [],
        };
        cart_items["Deposit"] = {
          // ~ for sorting below other ascii names
          id: "Deposit",
          qty: 1,
          price: 0,
          sizes: [],
          image: "",
          notes: "",
          categories: [],
        };
      }
    }
    cart_items[item["name"]]["qty"] += 1;

    this.setState({ cart_items: cart_items });
  }

  add_custom(data) {
    const cart_items = this.state.cart_items;
    cart_items[data.name] = {
      id: data.name,
      qty: data.qty,
      price: data.price,
      sizes: [],
      image: "",
      notes: data.notes,
      categories: [],
    };
    this.setState({ cart_items: cart_items });
  }

  make_product_rows() {
    const rows = [];
    let to_show = this.props.categories;
    if (!to_show) {
      return rows;
    }
    for (let key of this.state.active_category_keys) {
      to_show = to_show[key];
    }
    let to_show_items = [];
    if ("_items" in to_show) {
      to_show_items = to_show["_items"].map((val) => {
        val["type"] = "item";
        return val;
      });
    }
    let to_show_categories = Object.keys(to_show)
      .filter((val) => {
        return val !== "_items";
      })
      .map((val) => {
        return { name: val, type: "category" };
      });

    to_show = to_show_items.concat(to_show_categories);

    for (let i = 0; i < to_show.length; i++) {
      if (i % 3 === 0) {
        rows.push([]);
      }
      rows[rows.length - 1].push(to_show[i]);
    }
    return rows;
  }

  update_order(order_number) {
    // copy to drop state reference
    const client_info_data_copy = JSON.parse(
      JSON.stringify(this.state.client_info_data)
    );
    const cart_items_copy = JSON.parse(JSON.stringify(this.state.cart_items));
    const ship_date = this.state.ship_date;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        raw_order: {
          client_info_data: client_info_data_copy,
          cart_items: cart_items_copy,
          order_number: order_number,
          ship_date: ship_date,
          updated: true,
        },
      }),
    };

    this.props
      .try_request(`/api/order/${order_number}`, options, () => {
        this.props.alert("danger", "Failed to save order to backend");
        return null;
      })
      .then((data) => {
        if (data) {
          this.props.done_editing();
        }
      });
  }

  cache_order(order_number) {
    // copy to drop state reference
    const client_info_data_copy = JSON.parse(
      JSON.stringify(this.state.client_info_data)
    );
    const cart_items_copy = JSON.parse(JSON.stringify(this.state.cart_items));
    const ship_date = this.state.ship_date;

    all_orders.push({
      client_info_data: client_info_data_copy,
      cart_items: cart_items_copy,
      order_number: order_number,
      ship_date: ship_date,
    });

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_info_data: client_info_data_copy,
        cart_items: cart_items_copy,
        order_number: order_number,
        ship_date: ship_date,
      }),
    };

    this.props.try_request("/upload/order", options, () => {
      this.props.alert("danger", "Failed to save order to backend");
    });
  }

  render() {
    const rows = this.make_product_rows();
    let content;
    if (!this.state.cart_view) {
      content = rows.map((row, i) => {
        return (
          <div className="menu-row" key={i}>
            {row.map((item, i) => {
              return (
                <Item
                  key={i}
                  name={item["name"]}
                  item_info={item}
                  onClick={() => this.handleClick(item)}
                />
              );
            })}
          </div>
        );
      });
    } else {
      content = (
        <CartView
          change_qty={(item, value) => this.change_qty(item, value)}
          change_price={(item, value) => this.change_price(item, value)}
          change_notes={(item, value) => this.change_notes(item, value)}
          cart_items={this.state.cart_items}
          add_custom={(data) => {
            this.add_custom(data);
          }}
        />
      );
    }

    return (
      <div className="board">
        <BreadCrumb
          active_category_keys={this.state.active_category_keys}
          breadcrumb_click={(new_active_keys) => {
            console.log(new_active_keys);
            this.setState({ active_category_keys: new_active_keys });
          }}
          hidden={this.state.cart_view}
        />
        <MenuRow
          cart_items={this.state.cart_items}
          reset={() => this.reset()}
          cart_button_clicked={() => this.cart_button_clicked()}
          home_button_clicked={() => this.home_button_clicked()}
        />
        {content}
        {this.state.cart_view ? (
          <div className="bottom">
            <div className="board">
              <ShipDate
                is_input={true}
                ship_date={this.state.ship_date}
                ship_date_change={(value) => this.change_ship_date(value)}
              />
              <ClientInfo
                accounts={this.props.accounts}
                selected_value={this.state.selected_value}
                is_input={true}
                client_info_data={this.state.client_info_data}
                client_info_data_changed={(field, value) =>
                  this.client_info_data_changed(field, value)
                }
              />
            </div>
            <CartButtons
              editing={this.props.editing}
              client_info_data_changed={(field, value) =>
                this.client_info_data_changed(field, value)
              }
              accounts={this.props.accounts}
              download_order={() => {
                const order_number = Date.now();
                this.setState({ order_number: order_number }, () => {
                  let order = {
                    client_info_data: this.state.client_info_data,
                    cart_items: this.state.cart_items,
                    order_number: order_number,
                    ship_date: this.state.ship_date,
                    date: this.state.date,
                  };

                  download_file("order", order);

                  this.cache_order(order_number);
                  this.setState({
                    // deep copy cart items so that next order doesn't reference it
                    cart_items: JSON.parse(
                      JSON.stringify(this.state.cart_items)
                    ),
                  });
                });
              }}
              update_order={() => this.update_order(this.state.order_number)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class CartButtons extends React.Component {
  render() {
    let content = <></>;
    if (this.props.editing) {
      content = (
        <>
          <Button variant="primary" onClick={() => this.props.update_order()}>
            Save
          </Button>
        </>
      );
    } else {
      content = (
        <>
          <button
            className="download-button"
            onClick={(e) => {
              this.props.download_order();
            }}
          >
            Download Order
          </button>
          <button
            className="download-button"
            onClick={(e) => {
              this.props.client_info_data_changed("saved", "");
            }}
          >
            Save Client Info
          </button>
          <button
            className="download-button"
            onClick={(e) => {
              download_file("all_orders", { all_orders: all_orders });
            }}
          >
            Export Session Orders
          </button>
          <button
            className="download-button"
            onClick={(e) => {
              download_file("clients", {
                client_field_names: client_field_names,
                accounts: this.props.accounts,
              });
            }}
          >
            Export Client List
          </button>
        </>
      );
    }

    return content;
  }
}
export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      categories: [],
      logged_in: true,
      alert: null,
    };
  }

  pre_load_images(categories, images) {
    Object.keys(categories).forEach((key) => {
      if (key !== "_items") {
        this.pre_load_images(categories[key], images);
      } else {
        categories["_items"].forEach((item) => {
          if (item["image"] !== "" && !(item["image"] in images)) {
            let img = new Image();
            img.src = item["image"];
            images[item["image"]] = img;
          }
        });
      }
    });
  }

  load_static_data() {
    this.try_request("/api/products", {}, null).then((data) => {
      if (data) {
        this.setState({ categories: data.json });
        this.pre_load_images(data.json, images);
      }
    });
    this.try_request("/api/contacts", {}, null).then((data) => {
      if (data) this.setState({ accounts: data.json });
    });
  }

  componentDidMount() {
    if (this.state.logged_in) {
      this.load_static_data();
    }
  }

  login(pw) {
    this.try_request("/api/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pw: pw }),
    }).then((data) => {
      if (data.status === 200) {
        this.setState({ logged_in: true, alert: null });
        this.load_static_data();
      } else {
        let message = JSON.stringify(data.json);
        if ("message" in data.json) {
          message = data.json.message;
        }
        this.setState({
          logged_in: false,
          alert: { variant: "danger", message: message },
        });
      }
    });
  }

  // should go in a util file?
  try_request(url, options = {}, onfail = "alert") {
    return fetch(url, options)
      .then((response) => {
        return response.json().then((json) => {
          return { json: json, status: response.status };
        });
      })
      .then((data) => {
        if (data.status === 403) {
          this.setState({ logged_in: false });
          throw new Error("Not logged in");
        }
        if (data.status === 500) {
          throw new Error(JSON.stringify(data));
        }
        return data;
      })
      .catch((error) => {
        console.log(`Error in request to ${url}`, error);
        if (onfail === "alert") {
          this.alert("danger", `Server error: ${error}`);
        } else if (onfail) {
          onfail();
        }
      });
  }

  alert(variant, message, timeout = null) {
    this.setState({ alert: { variant: variant, message: message } });
    if (timeout) {
      window.setTimeout(() => {
        this.setState({ alert: null });
      }, timeout * 1000);
    }
  }

  render() {
    return (
      <div className="App">
        <Login
          logged_in={this.state.logged_in}
          alert={this.state.alert}
          login={(pw) => {
            this.login(pw);
          }}
        />
        {this.state.logged_in && this.state.alert ? (
          <Alert
            className="top-alert"
            variant={this.state.alert.variant}
            onClose={() => this.setState({ alert: null })}
            dismissible
          >
            {this.state.alert.message}
          </Alert>
        ) : (
          ""
        )}
        <Board
          categories={this.state.categories}
          accounts={this.state.accounts}
          editing={this.props.editing ?? null}
          edit_order={this.props.edit_order ?? null}
          done_editing={() => this.props.done_editing()}
          alert={(v, m, t) => {
            this.alert(v, m, t);
          }}
          try_request={this.try_request}
        />
      </div>
    );
  }
}

export default App;
