import React from "react";
import "./Orders.css";
import { download_order_files_from_api, toSummary } from "./Files.js";
import { Login, App } from "./App.js";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import CreatableSelect from "react-select/creatable";

class OrderRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.order_number}</td>
        <td>{this.props.client_name}</td>
        <td>{this.props.source}</td>
        <td>{this.props.date}</td>
        <td className="center-cell">
          <input
            type="checkbox"
            className="table-button"
            checked={this.props.confirmation_email}
            onChange={() => {
              this.props.order_toggle(
                this.props.order_number,
                "confirmation_email"
              );
            }}
          ></input>
        </td>
        <td className="center-cell">
          <input
            type="checkbox"
            className="table-button"
            checked={this.props.paid}
            onChange={() => {
              this.props.order_toggle(this.props.order_number, "paid");
            }}
          ></input>
        </td>
        <td className="center-cell">
          <input
            type="checkbox"
            className="table-button"
            checked={this.props.deposit}
            onChange={() => {
              this.props.order_toggle(this.props.order_number, "deposit");
            }}
          ></input>
        </td>
        <td className="center-cell">
          <input
            type="checkbox"
            className="table-button"
            checked={this.props.shipped}
            onChange={() => {
              this.props.order_toggle(this.props.order_number, "shipped");
            }}
          ></input>
        </td>
        <td className="center-cell">
          <button
            className="table-button"
            href=""
            onClick={() =>
              this.props.download_order(this.props.order_number, false)
            }
          >
            &#8595;
          </button>
        </td>
        <td className="center-cell">
          <button
            className="table-button"
            href=""
            onClick={() =>
              this.props.download_order(this.props.order_number, true)
            }
          >
            &#8595;
          </button>
        </td>
        {/* edit */}
        <td className="center-cell">
          <button
            className="table-button"
            href=""
            onClick={() => this.props.edit_order(this.props.order_number)}
          >
            &#9998;
          </button>
        </td>

        {/* delete */}
        <td className="center-cell">
          <button
            className="table-button"
            href=""
            onClick={() => this.props.remove_order(this.props.order_number)}
          >
            &#10006;
          </button>
        </td>
      </tr>
    );
  }
}

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
  }
  render() {
    return (
      <div className="search-wrapper">
        <CreatableSelect
          options={[{ value: "null", label: "n/a" }].concat(
            this.props.client_names.sort().map((name) => {
              return { value: name, label: name };
            })
          )}
          placeholder="client filter"
          create-option={true}
          name="source"
          value={this.state.selected}
          className="search"
          isSearchable={true}
          onChange={(e) => {
            if (e.value === "null" && e.label === "n/a") {
              this.props.update_client_filter(null);
              this.setState({ selected: null });
            } else {
              this.props.update_client_filter(e.value);
              this.setState({ selected: e });
            }
          }}
        />
      </div>
    );
  }
}

class Nav extends React.Component {
  render() {
    return (
      <div className="nav-box">
        <button
          className="nav-button"
          style={!this.props.prev ? { visibility: "hidden" } : {}}
          onClick={() => this.props.prev_page()}
        >
          &#x23F4;
        </button>
        <div style={{ margin: "0.5vh", display: "inline-block" }}>
          page {this.props.page}
        </div>

        <button
          className="nav-button"
          style={!this.props.more ? { visibility: "hidden" } : {}}
          onClick={() => this.props.next_page()}
        >
          &#x23F5;
        </button>
      </div>
    );
  }
}

function FilterButton(props) {
  let variant = "light";
  if (props.filtered) {
    variant = "primary";
  }
  return (
    <Button onClick={() => props.on_filter()} variant={variant}>
      ᗊ
    </Button>
  );
}

class DatePickers extends React.Component {
  render() {
    return (
      <div className="date-pickers">
        <label htmlFor="start_date" className="date-input">
          Download Start date:
        </label>

        <input
          type="date"
          id="start_date"
          className="date-input"
          name="start_date"
          value={this.props.start_date}
          onChange={(e) => {
            this.props.date_change(e.target.value, this.props.end_date);
          }}
        />
        <label htmlFor="end_date" className="date-input">
          Download End date:
        </label>
        <input
          type="date"
          id="end_date"
          className="date-input"
          name="end_date"
          value={this.props.end_date}
          onChange={(e) => {
            this.props.date_change(this.props.start_date, e.target.value);
          }}
        />
        <button
          className="date-button"
          onClick={() =>
            this.props.download_orders(
              this.props.start_date,
              this.props.end_date,
              null
            )
          }
        >
          Download order summary
        </button>
        <button
          className="date-button"
          onClick={() =>
            this.props.download_orders(
              this.props.start_date,
              this.props.end_date,
              false
            )
          }
        >
          Download not shipped
        </button>
      </div>
    );
  }
}

class OrdersTable extends React.Component {
  constructor(props) {
    super(props);
    let start_date = new Date();
    let end_date = new Date();
    start_date.setDate(start_date.getDate() - 30);

    this.state = {
      orders: [],
      client_names: [],
      client_filter: null,
      more: false,
      prev: false,
      page: 0,
      editing: null,
      start_date: start_date.toISOString().slice(0, 10),
      end_date: end_date.toISOString().slice(0, 10),
      filters: {
        paid: false,
        deposit: false,
        confirmation: false,
        shipped: false,
      },
    };
    this.intervalId = null;
  }

  download_order(order_number, internal = false) {
    console.log("downloading", order_number);
    let to_download = null;
    this.state.orders.some((order) => {
      if (order.order_number === order_number) {
        to_download = order;
        return true;
      }
      return false;
    });
    if (!to_download) {
      return;
    }
    let filename =
      "sarah_stewart_" +
      to_download.client_name +
      "_" +
      new Date().toJSON().slice(0, 10) +
      "_" +
      order_number +
      ".pdf";

    download_order_files_from_api(
      filename,
      to_download.raw_order.client_info_data.email,
      to_download.raw_order,
      to_download.date,
      to_download.raw_order.client_info_data.buyer,
      to_download.raw_order.client_info_data.source,
      internal,
      !internal
    );
  }

  order_toggle(order_number, field) {
    console.log("updating", order_number, field);
    let to_update = null;
    //copy to dorp reference
    const orders = JSON.parse(JSON.stringify(this.state.orders));
    orders.some((order) => {
      if (order.order_number === order_number) {
        to_update = order;
        return true;
      }
      return false;
    });
    if (!to_update) {
      return;
    }
    this.props
      .try_request(`/api/order/${order_number}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...to_update,
          [field]: !to_update[field],
        }),
      })
      .then((data) => {
        if (!data) {
          return;
        }
        console.log("success:", data.json);
        to_update[field] = !to_update[field];
        this.setState({ orders: orders });
      });
  }

  edit_order(order_number) {
    console.log("editing", order_number);
    this.setState({ editing: order_number });
  }

  remove_order(order_number) {
    console.log("removing", order_number);
    this.props
      .try_request(`/api/order/${order_number}`, { method: "DELETE" })
      .then((data) => {
        if (!data) {
          return;
        }
        const json = data.json;
        if ("result" in json && json.result === "success") {
          const orders = this.state.orders.filter(
            (order) => order.order_number !== order_number
          );
          this.setState({ orders: orders });
          this.refresh_orders(this.state.page);
        }
      });
  }

  on_filter(field) {
    const filters = this.state.filters;
    if (field in filters) {
      filters[field] = !filters[field];
      this.setState({ filters: filters });
      this.refresh_orders();
    }
  }

  download_orders(start_date, end_date, shipped = null) {
    const element = document.createElement("a");
    let url = `/api/orders_by_date?start=${start_date}&end=${end_date}`;
    if (shipped !== null) {
      url += `&shipped=${shipped}`;
    }
    for (let filter in this.state.filters) {
      if (this.state.filters[filter] === true) {
        url += encodeURI(`&${filter}=true`);
      }
    }
    this.props.try_request(url).then((data) => {
      if (!data) {
        return;
      }
      const json = data.json;
      const file = new Blob([toSummary(json)], {
        type: "text/plain",
      });

      element.download = "orders_" + start_date + "_" + end_date + ".csv";
      element.href = URL.createObjectURL(file);
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  }

  render() {
    return (
      <div className="wrapper">
        <EditOrder
          editing={this.state.editing}
          orders={this.state.orders}
          done_editing={() => {
            console.log("done editing");
            this.setState({ editing: false });
            this.refresh_orders();
          }}
        />
        <DatePickers
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          download_orders={(s, e, shipped) => {
            this.download_orders(s, e, shipped);
          }}
          date_change={(s, e) => {
            this.setState({ start_date: s, end_date: e }, () => {
              this.refresh_orders();
            });
          }}
        />
        <Nav
          page={this.state.page + 1}
          more={this.state.more}
          prev={this.state.prev}
          next_page={() => this.change_page("next")}
          prev_page={() => this.change_page("prev")}
        />
        <Search
          client_names={this.state.client_names}
          update_client_filter={(name) => {
            this.setState({ client_filter: name }, () => {
              this.refresh_orders();
            });
          }}
        />
        <table className="orders-table">
          <thead>
            <tr>
              <th id="order_number">Order Number</th>
              <th id="client_name">Client Name</th>
              <th id="source">Source</th>
              <th id="date">Date</th>
              <th id="confirmation">
                Confirmation <br></br>
                <FilterButton
                  filtered={this.state.filters.confirmation}
                  on_filter={() => this.on_filter("confirmation")}
                />
              </th>
              <th id="paid">
                Paid <br></br>
                <FilterButton
                  filtered={this.state.filters.paid}
                  on_filter={() => this.on_filter("paid")}
                />
              </th>
              <th id="deposit">
                Deposit <br></br>
                <FilterButton
                  filtered={this.state.filters.deposit}
                  on_filter={() => this.on_filter("deposit")}
                />
              </th>
              <th id="shipped">
                Shipped <br></br>
                <FilterButton
                  filtered={this.state.filters.shipped}
                  on_filter={() => this.on_filter("shipped")}
                />
              </th>
              <th id="download">Download+Email</th>
              <th id="download_internal">Download Internal</th>
              <th id="edit">Edit</th>
              <th id="delete">Delete</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((order) => {
              return (
                <OrderRow
                  key={order.order_number}
                  {...order}
                  order_toggle={(order_number, field) => {
                    this.order_toggle(order_number, field);
                  }}
                  remove_order={(order) => this.remove_order(order)}
                  edit_order={(order) => this.edit_order(order)}
                  download_order={(order, internal = false) =>
                    this.download_order(order, internal)
                  }
                />
              );
            })}
          </tbody>
        </table>
        {this.state.orders.length === 0 ? (
          <div className="wrapper">
            <h1>No orders in this date range.</h1>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  refresh_clients() {
    this.props
      .try_request("/api/orders/clients", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (!data) {
          return;
        }
        const json = data.json;
        this.setState({
          client_names: json.client_names,
        });
      });
  }

  refresh_orders(page = 0, scroll = true) {
    let filters = `page=${page}&start_date=${this.state.start_date}&end_date=${this.state.end_date}&show=50`;
    if (this.state.client_filter) {
      filters += encodeURI(`&client_name=${this.state.client_filter}`); // manually encode to make sure don't strip whitespace
    }
    for (let filter in this.state.filters) {
      if (this.state.filters[filter] === true) {
        filters += encodeURI(`&${filter}=true`);
      }
    }
    this.props
      .try_request(`/api/orders?${filters}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (!data) {
          return;
        }
        const json = data.json;
        this.setState({
          orders: json.orders,
          prev: json.prev,
          more: json.more,
          page: page,
        });
        if (scroll) {
          window.scrollTo(0, 0);
        }
      });
  }

  change_page(direction) {
    let page = this.state.page;
    if (direction === "next") {
      page++;
    } else if (direction === "prev") {
      page--;
    }
    this.refresh_orders(page, true);
  }

  componentDidMount() {
    this.refresh_orders();
    this.refresh_clients();
    this.intervalId = setInterval(() => {
      this.refresh_orders(this.state.page, false);
    }, 5000);
  }

  componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  }
}

function EditOrder(props) {
  let order = null;
  if (props.editing) {
    order =
      props.orders.find((obj) => {
        return obj.order_number === props.editing;
      }) ?? null;

    if (order) {
      order = order["raw_order"];
    }
  }

  return (
    <Modal
      show={props.editing}
      onHide={() => props.done_editing()}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit order #{props.editing}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <App
          editing={props.editing}
          edit_order={order}
          done_editing={() => props.done_editing()}
        />
      </Modal.Body>
    </Modal>
  );
}

class OrdersApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: true,
      alert: null,
    };
  }

  login(pw) {
    this.try_request("/api/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pw: pw }),
    }).then((data) => {
      if (data.status === 200) {
        this.setState({ logged_in: true, alert: null });
      } else {
        let message = JSON.stringify(data.json);
        if ("message" in data.json) {
          message = data.json.message;
        }
        this.setState({
          logged_in: false,
          alert: { variant: "danger", message: message },
        });
      }
    });
  }

  alert_func(variant, message) {
    this.setState({ alert: { variant: variant, message: message } });
  }
  // should go in a util file?
  try_request(url, options = {}, onfail = "alert") {
    let promise = fetch(url, options);
    return promise
      .then((response) => {
        return response.json().then((json) => {
          return { json: json, status: response.status };
        });
      })
      .then((data) => {
        if (data.status === 403) {
          this.setState({ logged_in: false });
          return;
        }
        return data;
      })
      .catch((error) => {
        console.log(`Error in request to ${url}`, error);
        if (onfail === "alert") {
          this.alert_func("danger", `Server error`);
        } else if (onfail) {
          onfail();
        }
      });
  }

  render() {
    return (
      <div className="OrdersApp">
        <Login
          logged_in={this.state.logged_in}
          alert={this.state.alert}
          login={(pw) => {
            this.login(pw);
          }}
        />
        {this.state.logged_in && this.state.alert ? (
          <Alert
            className="top-alert"
            variant={this.state.alert.variant}
            onClose={() => this.setState({ alert: null })}
            dismissible
          >
            {this.state.alert.message}
          </Alert>
        ) : (
          ""
        )}
        <OrdersTable
          key={this.state.logged_in} // force a refresh when logging in
          try_request={(url, options, onfail) =>
            this.try_request(url, options, onfail)
          }
        />
      </div>
    );
  }
}
export default OrdersApp;
