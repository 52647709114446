import React, { useState } from "react";
import "./App.css";
import { Login } from "./App.js";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AddProduct(props) {
  const [state, setState] = useState({
    name: "",
    price: null,
    category_1: "",
    category_2: "",
    category_3: "",
    image: "",
  });

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.alert ? (
          <Alert variant={props.alert.variant}>{props.alert.message}</Alert>
        ) : (
          ""
        )}
        <label htmlFor="product_name">Name:</label>
        <input
          name="product_name"
          type="text"
          onChange={(e) => {
            state.name = e.target.value.trim();
            setState(state);
          }}
        ></input>
        <label htmlFor="category_1">Category 1:</label>
        <input
          name="category_1"
          type="text"
          onChange={(e) => {
            state.category_1 = e.target.value.trim();
            setState(state);
          }}
        ></input>
        <label htmlFor="category_2">Category 2:</label>
        <input
          name="category_2"
          type="text"
          onChange={(e) => {
            state.category_2 = e.target.value.trim();
            setState(state);
          }}
        ></input>
        <label htmlFor="category_3">Category 3:</label>
        <input
          name="category_3"
          type="text"
          onChange={(e) => {
            state.category_3 = e.target.value.trim();
            setState(state);
          }}
        ></input>
        <label htmlFor="product_price">Price:</label>
        <input
          name="product_price"
          type="number"
          onChange={(e) => {
            state.price = parseFloat(e.target.value);
            setState(state);
          }}
        ></input>
        <label htmlFor="product_image">Image link:</label>
        <input
          name="product_image"
          type="text"
          onChange={(e) => {
            state.image = e.target.value.trim();
            setState(state);
          }}
        ></input>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (props.add_product(state)) {
              state.name = "";
              state.price = null;
              state.category_1 = "";
              state.category_2 = "";
              state.category_3 = "";
              state.image = "";
              setState(state);
            }
          }}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

class ProductAddApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: true,
      alert: null,
      show_add_product: false,
    };
    this.try_request(
      "/api/auth",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      () => {
        this.setState({ logged_in: false });
      }
    );
  }

  login(pw) {
    this.try_request("/api/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pw: pw }),
    }).then((data) => {
      if (data.status === 200) {
        this.setState({ logged_in: true, alert: null });
      } else {
        let message = JSON.stringify(data.json);
        if ("message" in data.json) {
          message = data.json.message;
        }
        this.setState({
          logged_in: false,
          alert: { variant: "danger", message: message },
        });
      }
    });
  }

  alert_func(variant, message) {
    this.setState({ alert: { variant: variant, message: message } });
  }
  // should go in a util file!
  try_request(url, options = {}, onfail = "alert") {
    let promise = fetch(url, options);
    return promise
      .then((response) => {
        return response.json().then((json) => {
          return { json: json, status: response.status };
        });
      })
      .then((data) => {
        if (data.status === 403) {
          this.setState({ logged_in: false });
          return;
        }
        return data;
      })
      .catch((error) => {
        console.log(`Error in request to ${url}`, error);
        if (onfail === "alert") {
          this.alert_func("danger", `Server error`);
        } else if (onfail) {
          onfail();
        }
      });
  }

  add_product(product) {
    if (product.name === "" || product.price === null) {
      this.alert_func("danger", "Name and price required");
    }
    console.log("adding", JSON.stringify(product));
    return this.try_request(
      "/upload/product",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ product: product }),
      },
      () => {
        this.alert_func("danger", "Failed to add product");
      }
    ).then((data) => {
      if (data && data.status === 200) {
        console.log(data);
        this.alert_func("success", "Saved Product", 2);
        return true;
      }
      return false;
    });
  }

  render() {
    return (
      <div className="AddProductApp">
        <Login
          logged_in={this.state.logged_in}
          alert={this.state.alert}
          login={(pw) => {
            this.login(pw);
          }}
        />
        {this.state.logged_in &&
        this.state.alert &&
        !this.state.show_add_product ? (
          <Alert
            className="top-alert"
            variant={this.state.alert.variant}
            onClose={() => this.setState({ alert: null })}
            dismissible
          >
            {this.state.alert.message}
          </Alert>
        ) : (
          ""
        )}
        <AddProduct
          show={this.state.show_add_product}
          alert={this.state.alert}
          add_product={(data) => {
            this.add_product(data).then((success) => {
              console.log("asdfaasdfa", success);
              if (success) {
                if (success) {
                  this.setState({ show_add_product: false });
                }
                return success;
              }
            });
          }}
          onHide={() =>
            this.setState({ show_add_product: !this.state.show_add_product })
          }
        />
        <div className="vertical-center">
          <div className="add-product-button-wrapper">
            <Button
              className="add-product-button"
              onClick={() =>
                this.setState({
                  show_add_product: !this.state.show_add_product,
                })
              }
            >
              Add Product
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductAddApp;
