import "./App.css";

function collect_products_and_categories(all_orders) {
  let products = [];
  let categories = {};
  all_orders.forEach((order) => {
    Object.keys(order.cart_items).forEach((key) => {
      let escaped_product = `"${key}"`; // add quotes for csv escape
      if (!products.includes(escaped_product)) {
        products.push(escaped_product);
        categories[escaped_product] = [];
        // backwards compatibility, older orders do not have categories saved
        if ("categories" in order.cart_items[key]) {
          order.cart_items[key].categories.forEach((category) => {
            let escaped_category = `"${category}"`;
            categories[escaped_product].push(escaped_category);
          });
        }
      }
    });
  });
  products.sort();

  return [products, categories];
}

export function toSummary(all_orders, transpose = true) {
  const columns = [
    "order_number",
    "name",
    "ship_date",
    "order_date",
    "notes",
    "email",
    "confirmation",
    "paid",
    "deposit",
    "shipped",
    "total_sale_value",
    "deposit_value",
    "shipping_value",
  ];

  let [products, categories] = collect_products_and_categories(all_orders);

  let category_row = Array(columns.length).fill("");
  category_row[0] = "Categories";
  products.forEach((product) => {
    category_row.push(categories[product].join("|"));
  });

  const rows = [category_row, columns.concat(products)];

  all_orders.forEach((order) => {
    const client_info_data = order.client_info_data;
    const order_number = order.order_number;
    const cart_items = order.cart_items;

    const product_counts = [];
    let total_sale_value = 0;
    let deposit_value = 0;
    let shipping_value = 0;
    products
      .map((p) => p.slice(1, -1)) // remove quotes for checking in cart_items
      .forEach((product) => {
        if (product in cart_items) {
          if (product === "Deposit") {
            deposit_value += Math.abs(
              cart_items[product].qty * cart_items[product].price
            );
            return;
          }

          if (product === "Shipping") {
            shipping_value =
              cart_items[product].qty * cart_items[product].price;
            return;
          }

          product_counts.push(cart_items[product].qty);
          total_sale_value +=
            cart_items[product].qty * cart_items[product].price;
        } else {
          product_counts.push(0);
        }
      });

    rows.push(
      [
        `"${order_number}"`,
        `"${client_info_data.name}"`,
        `"${order.ship_date}"`,
        `"${order.date}"`,
        `"${client_info_data.notes}"`,
        `"${client_info_data.email}"`,
        `"${order.confirmation_email}"`,
        `"${order.paid}"`,
        `"${order.deposit}"`,
        `"${order.shipped}"`,
        `"${total_sale_value.toFixed(2)}"`,
        `"${deposit_value.toFixed(2)}"`,
        `"${shipping_value.toFixed(2)}"`,
      ].concat(product_counts)
    );
  });

  const totals = ["Total"];
  const padding = columns.length;
  totals[padding - 1] = 0; // sale value total
  rows.slice(2).forEach((row) => {
    totals[padding - 1] += parseFloat(row[padding - 1].slice(1, -1)); // remove quotes for sale value
    row.slice(padding).forEach((value, i) => {
      if (!totals[i + padding]) {
        totals[i + padding] = 0;
      }
      totals[i + padding] += value;
    });
  });
  totals[padding - 1] = `"${totals[padding - 1].toFixed(2)}"`;
  rows.push(totals);

  if (transpose) {
    const transposed = [];
    for (let col = 0; col < rows[0].length; col++) {
      transposed.push([]);
      for (let row = 0; row < rows.length; row++) {
        transposed[col].push(rows[row][col]);
      }
    }
    return transposed.map((row) => row.join(",")).join("\n");
  }

  return rows.map((row) => row.join(",")).join("\n");
}

function toCSV(accounts, client_field_names) {
  let lines = [];
  lines.push(client_field_names.join(","));
  Object.keys(accounts).forEach((key) => {
    const account = accounts[key];
    lines.push(
      client_field_names
        .map((key) => {
          return account[key];
        })
        .join(",")
    );
  });
  return lines.join("\n");
}

export function download_order_files_from_api(
  filename,
  email = null,
  order,
  date,
  buyer_name = null,
  market_name = null,
  download_internal = false,
  download_client = true,
  onfail = null
) {
  let to_download = [];
  if (download_internal) {
    to_download.push({
      filename: "INTERNAL_" + filename,
      email: null,
      include_billing: true,
    });
  }
  if (download_client) {
    to_download.push({
      filename: filename,
      email: email,
      include_billing: false,
    });
  }

  to_download.forEach((doc) => {
    fetch("/api/pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      body: JSON.stringify({
        order: { ...order, date: date },
        include_billing: doc.include_billing,
      }),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          return response.blob();
        } else {
          throw new Error("Failed to download PDF");
        }
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // Send email if needed
        const element = document.createElement("a");
        setTimeout(() => {
          if (doc.email) {
            buyer_name = buyer_name === null ? "" : " " + buyer_name.trim();
            const body = `Hi${buyer_name},%0D%0A%0D%0AIt was so lovely to see you at ${market_name}!%0D%0A%0D%0AThank you for your order, and please find your confirmation attached. We will be in touch with shipping details. In the meantime, please let me know if you have any questions.%0D%0A%0D%0AThank you again for supporting our small business!%0D%0A%0D%0AAll the Best,%0D%0ASarah Stewart%0D%0A`;
            element.href = `mailto:${doc.email}?subject=Sarah Stewart Order: #${order.order_number}&cc=hello@shopsarahstewart.com,sarah@shopsarahstewart.com&body=${body}`;
            element.click();
          }
          element.remove();
        }, 1000);
      })
      .catch((error) => {
        console.log(`Error`, error);
        if (onfail) {
          onfail();
        }
      });
  });
}

export function download_file(type, data) {
  const today = new Date().toJSON().slice(0, 10);
  const element = document.createElement("a");
  let file;
  if (type === "clients") {
    console.log("downloading csv");
    file = new Blob([toCSV(data.accounts, data.client_field_names)], {
      type: "text/plain",
    });
    element.download = "clients_" + today + ".csv";
    element.href = URL.createObjectURL(file);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  } else if (type === "order") {
    console.log("downloading order", data.order_number);

    let filename =
      "sarah_stewart_" +
      data.client_info_data.name +
      "_" +
      today +
      "_" +
      data.order_number +
      ".pdf";

    let order = data;
    let date = data.date;
    download_order_files_from_api(filename, null, order, date);
  } else if (type === "all_orders") {
    file = new Blob([toSummary(data.all_orders)], {
      type: "text/plain",
    });
    element.download = "orders_" + today + ".csv";
    element.href = URL.createObjectURL(file);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  }
}
